/***
 * Preview Editor:
 */

.preview-editor {
	
	/**
	 * Preview editor:
	 * `.preview-editor` - Styles a preview editor with a model editor inside
	 * 
	 * 	@example
	 * 	div.preview-editor
	 * 		div.previews
	 * 			div.proxy Template1
	 * 			div.proxy.active Template2
	 * 			div.model-editor
	 * 				h2 Model
	 * 				div.inputs.col.col-1-1
	 * 					div.string.input
	 * 						div.label Input
	 * 						div.value.form
	 */
	 
	 & > .previews {
	 
	 	& >.proxy {
	 		cursor: pointer;
	 		border-left: 0px solid @info-color;
	 		padding: 5px 10px;
	 		-webkit-transition: all 0.5s;
			transition: all 0.5s;
			position: relative;
			
	 		
	 		&.selected {
				border-left: 8px solid @info-color;
				margin-top: 20px;
			}
			
			&:hover {
				background-color: lighten( @info-color, 35% );
				
				& > .mod-container {
					opacity: 1;
				}
			}
			
			& > .mod-container {
			
				opacity: 0;
				transition: all 0.5s;
				
				.icon--btn {
					color: @action-color;
				}
			}
	 	}
	 
	 	& >.edit >.model-editor {
	 
		 	padding: 10px;
			margin-bottom: 20px;
			
		 	> h2 {
		 		margin-top: 0;
		 		font-size: 1.2em;
		 	}
		 	
		 	&.active {
				-webkit-animation: border-fade 0.5s;
				animation: border-fade 0.5s;
				border-left: 8px solid @info-color;
			}
		}
	}
}

/**
 * Fix for animate the border of the newly placed model editor
 */
@-webkit-keyframes border-fade {
	from {
		border-left: 0px solid @info-color;
	}
	to {
		border-left: 8px solid @info-color;
	}
}

@keyframes border-fade {
	from {
		border-left: 0px solid @info-color;
	}
	to {
		border-left: 8px solid @info-color;
	}
}
/***
 * Buttons:
 */

/**
 * Regular Button:
 * `.btn` - A regular button
 * 
 * 	@example
 * 	button.btn Button
 */
.btn {

	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;

	font: inherit;
	background-color: @action-color;
	padding: 7px 12px;
	line-height: 1.3em;
	color:#fff;
	border: none;
	font-size: 1em;
	font-weight: 200;

	cursor:pointer;
	border-radius:@border-radius;
	outline:none;
	
	transition: background-color 0.3s;

	&:hover {
		background-color: lighten( @action-color, 20% );
	}
	

	/**
	 * Disabled button:
	 * `.disabled` - A style for a disabled button
	 * 
	 * 	@example
	 * 	button.btn.disabled Disabled
	 */
	&.disabled {
		background-color: lighten( desaturate( @action-color, 80% ), 30% );
		cursor: default;
		color: desaturate( @action-color, 80% );
	}
	
	/**
	 * Back Button:
	 * `.back` - Turns the button into a back button with an arrow on the left side
	 * 
	 * 	@example
	 * 	button.btn.back Back
	 */
	&.back {
		position: relative;
		
		&:hover:before {
			background-color: lighten( @action-color, 20% );
		}
		
		&:before {
			content: '';
			position: absolute;
			height: 24px;
			width: 24px;
			background-color: @action-color;
			left: -11px;
			top: 5px;
			border-radius: @border-radius;
			transition: background-color 0.3s;
			
			-webkit-transform: scaleX( 0.7 ) rotate( 45deg );
			-moz-transform: scaleX( 0.7 ) rotate( 45deg );
			-o-transform: scaleX( 0.7 ) rotate( 45deg );
			-ms-transform: scaleX( 0.7 ) rotate( 45deg );
			transform: scaleX( 0.7 ) rotate( 45deg );
		}
	}
	
	/**
	 * Secondary Button:
	 * `.second` - Turns the button into a secondary button
	 * 
	 * 	@example
	 * 	button.btn.second Secondary button
	 */
	&.second {
		padding: 7px 12px;
		line-height: 1.1em;
		font-size: 0.8em;
		background-color: desaturate( @action-color, 40% );
		
		&:hover {
			background-color: @action-color;
		}
	}
	
	
	&.icon {
		background: none;
		padding: 0;
		line-height:1em;
		border: 1px solid @action-color;
		transition: all 0.3s;
		
		&:hover {
			>.icon {
				background-color: lighten( @action-color, 20% );
			}
			border: 1px solid lighten( @action-color, 20% );
		}
		
		>.icon {
			transition: all 0.3s;
			line-height:1em;
			font-size: 18px;
			padding: 3px 5px;
			background-color: @action-color;
			vertical-align: middle;
		}
		
		>.icon-button--text {
			color: @dark-color;
			font-size: 0.7em;
			line-height:1em;
			padding: 4px 10px;
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		}
	}
}

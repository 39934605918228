/***
 * Tabs:
 */

/**
 * tabs:
 * `.tabs` - A basic styling for all tabs forms
 * 
 * 	@example
 * 	ol.tabs
 * 		li Tab1
 * 		li Tab2 with very long text.
 * 		li Tab3
 */
.tabs {
	
	list-style: none;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	
	> li {
		cursor: pointer;
		padding: 6px 10px;
		border: 1px solid @info-color;
		border-radius: @border-radius;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		box-sizing: border-box;
		min-height: 34px;
		vertical-align: bottom;
		
		&:hover {
			background-color: @action-color;
			border-color: @action-color;
			color: #FFF;
		}
		
		&.selected {
			border-color: @info-color;
			background-color: @info-color;
			color: #FFF;
			font-weight: lighter;
		}
	}
	

	/**
	 * Top tabs:
	 * `.tabs.top` - Styles the tabs for horizontal aligment on the top
	 * 
	 * 	@example
	 * 	ol.tabs.top
	 * 		li Tab1
	 * 		li.active Tab2
	 * 		li Tab3
	 */
	&.top {
	
		border-bottom: 2px solid @info-color;
		padding: 0 2px;
		
		> li {
			border-bottom: 1px solid @info-color;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			margin-bottom: -1px;
			margin-right: 2px;
			display: inline-block;
			max-width: 100% / 10;
			
		}
	}

	/**
	 * Left tabs:
	 * `.tabs.left` - Styles the tabs for vertical alignment on the left side
	 * 
	 * 	@example
	 * 	ol.tabs.left
	 * 		li Tab1
	 * 		li.active Tab2
	 * 		li Tab3
	 */
	&.left {
		
		border-right: 2px solid @info-color;
		padding: 2px 0;
		
		> li {
			border-right: 1px solid @info-color;
			margin-bottom: 2px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			margin-right: -2px;
			
			&:hover {
				border-right-color: @info-color;
				border-right: 2px solid @info-color;
			}
		}
		
	}

	/**
	 * Right tabs:
	 * `.tabs.right` - Styles the tabs for vertical alignment on the right side
	 * 
	 * 	@example
	 * 	ol.tabs.right
	 * 		li Tab1
	 * 		li.active Tab2
	 * 		li Tab3
	 */
	&.right {
		
		border-left: 2px solid @info-color;
		padding: 2px 0;
		
		> li {
			border-left: 1px solid @info-color;
			margin-bottom: 2px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			margin-left: -1px;
			
		}
	}
}
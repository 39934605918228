@charset "UTF-8";
/***
 * Color variables:
 */
/**
 * Main color:
 * `@action-color`
 * The main action color.
 *
 * 	@example
 * 	div(style='width:50px;height:50px;background-color:#376491')
 */
/**
 * Info color:
 * `@info-color`
 * The color for infomations
 *
 * 	@example
 * 	div(style='width:50px;height:50px;background-color:#FE6321')
 */
/**
 * Bright color:
 * `@bright-color`
 * A bright color 
 *
 * 	@example
 * 	div(style='width:50px;height:50px;background-color:#EFEFE7')
 */
/**
 * Dark color:
 * `@dark-color`
 * A dark color 
 *
 * 	@example
 * 	div(style='width:50px;height:50px;background-color:#3B4344')
 */
/**
 * Error color:
 * `@error-color`
 * A error like color 
 *
 * 	@example
 * 	div(style='width:50px;height:50px;background-color:#ED3F40')
 */
/**
 * Correct color:
 * `@correct-color`
 * A success/correct color 
 *
 * 	@example
 * 	div(style='width:50px;height:50px;background-color:#1CA52D')
 */
/***
 * Less variables:
 */
/**
 * Responsive breakpoint:
 * `@breakpoint` - The responsive break point when the content should be listed vertically.
 */
/**
 * Border radius:
 * `@border-radius` - The commonly used border radius
 * 
 * 	@example
 * 	div(style='width:50px;height:50px;border-radius:3px;background-color:#666')
 */
/***
 * Less functions:
 */
/**
 * Cleafix:
 * `.clearfix()` - Adds a clearfix to an element
 */
/**
 * Columns:
 * `.columns` - Setups a style for floating columns
 */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700,400,300);
@font-face {
  font-family: "dateneditor";
  src: url("fonts/dateneditor.eot");
  src: url("fonts/dateneditor.eot?#iefix") format("embedded-opentype"), url("fonts/dateneditor.woff") format("woff"), url("fonts/dateneditor.ttf") format("truetype"), url("fonts/dateneditor.svg#dateneditor") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "dateneditor" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "dateneditor" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-plus:before {
  content: "\63";
}
.icon-close:before {
  content: "\61";
}
.icon-erase:before {
  content: "\62";
}
.icon-italic:before {
  content: "\65";
}
.icon-underline:before {
  content: "\66";
}
.icon-group:before {
  content: "\6b";
}
.icon-bold:before {
  content: "\64";
}
.icon-page-copy:before {
  content: "\6c";
}
.icon-pencil:before {
  content: "\6d";
}
.icon-external-link:before {
  content: "\6e";
}
.icon-success:before {
  content: "\68";
}
.icon-error:before {
  content: "\69";
}
.icon-import:before {
  content: "\67";
}
.icon-list-number:before {
  content: "\6a";
}
.icon-list-bullet:before {
  content: "\6f";
}
.icon-file-symlink-file:before {
  content: "\70";
}
.icon-strikethrough:before {
  content: "\71";
}
.icon-superscript:before {
  content: "\72";
}
.icon-subscript:before {
  content: "\73";
}
.icon-link:before {
  content: "\74";
}
.icon-quote:before {
  content: "\76";
}
.icon-align-center:before {
  content: "\77";
}
.icon-align-justify:before {
  content: "\78";
}
.icon-align-left:before {
  content: "\79";
}
.icon-align-right:before {
  content: "\7a";
}
.icon-code:before {
  content: "\41";
}
/***
 * Buttons:
 */
/**
 * Regular Button:
 * `.btn` - A regular button
 * 
 * 	@example
 * 	button.btn Button
 */
.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
  background-color: #369BFF;
  padding: 7px 12px;
  line-height: 1.3em;
  color: #fff;
  border: none;
  font-size: 1em;
  font-weight: 200;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.3s;
  /**
	 * Disabled button:
	 * `.disabled` - A style for a disabled button
	 * 
	 * 	@example
	 * 	button.btn.disabled Disabled
	 */
  /**
	 * Back Button:
	 * `.back` - Turns the button into a back button with an arrow on the left side
	 * 
	 * 	@example
	 * 	button.btn.back Back
	 */
  /**
	 * Secondary Button:
	 * `.second` - Turns the button into a secondary button
	 * 
	 * 	@example
	 * 	button.btn.second Secondary button
	 */
}
.btn:hover {
  background-color: #9cceff;
}
.btn.disabled {
  background-color: #e2e7ec;
  cursor: default;
  color: #869baf;
}
.btn.back {
  position: relative;
}
.btn.back:hover:before {
  background-color: #9cceff;
}
.btn.back:before {
  content: '';
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: #369BFF;
  left: -11px;
  top: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
  -webkit-transform: scaleX(0.7) rotate(45deg);
  -moz-transform: scaleX(0.7) rotate(45deg);
  -o-transform: scaleX(0.7) rotate(45deg);
  -ms-transform: scaleX(0.7) rotate(45deg);
  transform: scaleX(0.7) rotate(45deg);
}
.btn.second {
  padding: 7px 12px;
  line-height: 1.1em;
  font-size: 0.8em;
  background-color: #5e9bd7;
}
.btn.second:hover {
  background-color: #369BFF;
}
.btn.icon {
  background: none;
  padding: 0;
  line-height: 1em;
  border: 1px solid #369BFF;
  transition: all 0.3s;
}
.btn.icon:hover {
  border: 1px solid #9cceff;
}
.btn.icon:hover > .icon {
  background-color: #9cceff;
}
.btn.icon > .icon {
  transition: all 0.3s;
  line-height: 1em;
  font-size: 18px;
  padding: 3px 5px;
  background-color: #369BFF;
  vertical-align: middle;
}
.btn.icon > .icon-button--text {
  color: #3B4344;
  font-size: 0.7em;
  line-height: 1em;
  padding: 4px 10px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
/***
 * Text styles:
 */
/**
 * H1:
 * `h1` - The h1 style
 * 
 * 	@example
 * 	h1 A demo h1 header
 */
h1 {
  font-weight: normal;
  font-size: 2.2em;
  margin-top: 41px;
  padding-bottom: 8px;
  border-bottom: 1px solid #EFEFE7;
  color: #3B4344;
}
/**
 * H2:
 * `h2` - The h2 style
 * 
 * 	@example
 * 	h2 A demo h2 header
 */
h2 {
  font-weight: bold;
  font-size: 1.6em;
  margin-top: 20px;
  margin-bottom: 5px;
  color: #3B4344;
}
/**
 * Body:
 * `body` - A body style for font.
 * 
 * 	@example
 * 	div My Test Font
 */
body {
  font-family: 'Source Sans Pro', sans-serif;
}
/**
 * Label:
 * `.label` - A styling for a label
 * 
 * 	@example
 * 	div.label My label
 */
.label {
  font-size: 0.7em;
  font-weight: normal;
  text-transform: uppercase;
  color: #369BFF;
  margin-bottom: 4px;
}
/**
 * Icon:
 * `.icon` - Default icon size 
 * 
 * 	@example
 * 	div.icon
 * 		div.icon(data-icon="b")
 */
.icon {
  font-size: 22px;
  display: inline-block;
  padding: 6px 0 0 0;
  /**
	 * Button icon:
	 * `.icon.icon--btn` - A styling for a icon button
	 * 
	 * 	@example
	 * 	div.icon.icon--btn(data-icon="b")
	 */
}
.icon.icon--btn {
  color: #369BFF;
  cursor: pointer;
  transition: color 0.3s;
  /**
		 * Inverted button icon:
		 * `.icon.icon--btn.icon--inv` - A styling for an inverted icon button
		 * 
		 * 	@example
		 * 	div.icon.icon--btn.icon--inv(data-icon="b")
		 */
}
.icon.icon--btn:hover {
  color: #9cceff;
}
.icon.icon--btn.icon--inv {
  color: #FFF;
}
.icon.icon--btn.icon--inv:hover {
  color: #cccccc;
}
/**
 * info:
 * `.info-text` - A small info text
 * 
 * 	@example
 * 	div.info-text Info message
 */
.info-text {
  color: #9faaac;
  padding: 5px 0;
  font-style: italic;
}
/***
 * Reponsive:
 */
/**
 * Container:
 * `.container` - A main website container that centers the container and has the corresponing responsive jumps
 * 
 * 	@example
 * 	div.container(style='background-color:#AAA;height:50px;') 
 */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
/**
 * Columns:
 * `.col` - A base styling for any number of columns
 * 
 * 	@example
 * 	div.col
 */
@media (min-width: 768px) {
  .col {
    margin-left: -15px;
    margin-right: -15px;
  }
  .col:before,
  .col:after {
    content: " ";
    display: table;
  }
  .col:after {
    clear: both;
  }
  .col > * {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    float: left;
  }
}
/**
 * Half column width modifier:
 * `.half` - A style that defines the width to 50%.
 * 
 * 	@example
 * 	div.col
 * 		div.half(style="background-color:#ccc") One side
 * 		div.half(style="background-color:#aaa") Other side
 */
@media (min-width: 768px) {
  .half {
    width: 50%;
  }
}
/**
 * Third column width modifier:
 * `.third` - A style that defines the width to 100/3 %.
 * 
 * 	@example
 * 	div.col
 * 		div.third(style="background-color:#ccc") One side
 * 		div.third(style="background-color:#aaa") Other side
 * 		div.third(style="background-color:#bbb") Third side
 */
@media (min-width: 768px) {
  .third {
    width: 33.33333333%;
  }
}
/**
 * Full column width modifier:
 * `.full` - A style that defines the width to 100%.
 * 
 * 	@example
 * 	div.col
 * 		div.full(style="background-color:#ccc") Full content
 */
.full {
  width: 100%;
}
/**
 * 80% / 20% column width modifier:
 * `.col-80, .col-20` - Two styles that defines the width to 80% or 20%.
 * 
 * 	@example
 * 	div.col
 * 		div.col-80(style="background-color:#ccc") One side
 * 		div.col-20(style="background-color:#aaa") Other side
 */
@media (min-width: 768px) {
  .col-20 {
    width: 20%;
  }
}
@media (min-width: 768px) {
  .col-80 {
    width: 80%;
  }
}
/**
 * Responsive image container:
 * `.responsive-img` - A style that makes the image scaling to its max width.
 * 
 * 	@example
 * 	img.responsive-img(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
 */
.responsive-img {
  max-width: 100%;
  height: auto;
}
/**
 * Responsive 4:3/16:9 media container:
 * `.responsive-media .media-4-3, .responsive-media .media-16-9` - This makes a div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
 * The media element needs the class `.media`. The `.responsive-media` container can have one of the following classes: `.media-4-3`, `.media-16-9` to define the fix ratio.
 * 
 * 	@example
 * 	div.responsive-media.media-4-3
 * 		iframe.media(src='//www.youtube.com/embed/HhZaHf8RP6g',frameborder='0')
 */
.responsive-media {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.responsive-media > .media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-media.media-4-3 {
  padding-bottom: 75%;
}
.responsive-media.media-16-9 {
  padding-bottom: 56.26%;
}
/***
 * Form definitions:
 */
/**
 * form:
 * `.form` - A style for all types of forms. 
 * 
 * 	@example
 * 	input.form(value="Text")
 */
.form {
  font: inherit;
  display: block;
  width: 100%;
  padding: 6px 12px;
  line-height: 1.3em;
  min-height: 34px;
  font-size: 1em;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  box-sizing: border-box;
  position: relative;
  /**
	 * Form with icon:
	 * `.form > .icon` - A form with an overlying icon on the right side
	 * 
	 * 	@example
	 * 	div.form Text
	 * 		div.icon(data-icon="b")
	 */
}
.form > .icon {
  position: absolute;
  top: 0;
  right: 6px;
}
.form:focus {
  border-color: #369BFF;
  outline: none;
  -webkit-box-shadow: inset 0 1px 1px #cfe7ff;
  box-shadow: inset 0 1px 1px #cfe7ff;
}
select.form {
  height: 34px;
}
/***
 * Tabs:
 */
/**
 * tabs:
 * `.tabs` - A basic styling for all tabs forms
 * 
 * 	@example
 * 	ol.tabs
 * 		li Tab1
 * 		li Tab2 with very long text.
 * 		li Tab3
 */
.tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /**
	 * Top tabs:
	 * `.tabs.top` - Styles the tabs for horizontal aligment on the top
	 * 
	 * 	@example
	 * 	ol.tabs.top
	 * 		li Tab1
	 * 		li.active Tab2
	 * 		li Tab3
	 */
  /**
	 * Left tabs:
	 * `.tabs.left` - Styles the tabs for vertical alignment on the left side
	 * 
	 * 	@example
	 * 	ol.tabs.left
	 * 		li Tab1
	 * 		li.active Tab2
	 * 		li Tab3
	 */
  /**
	 * Right tabs:
	 * `.tabs.right` - Styles the tabs for vertical alignment on the right side
	 * 
	 * 	@example
	 * 	ol.tabs.right
	 * 		li Tab1
	 * 		li.active Tab2
	 * 		li Tab3
	 */
}
.tabs > li {
  cursor: pointer;
  padding: 6px 10px;
  border: 1px solid #369BFF;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  min-height: 34px;
  vertical-align: bottom;
}
.tabs > li:hover {
  background-color: #369BFF;
  border-color: #369BFF;
  color: #FFF;
}
.tabs > li.selected {
  border-color: #369BFF;
  background-color: #369BFF;
  color: #FFF;
  font-weight: lighter;
}
.tabs.top {
  border-bottom: 2px solid #369BFF;
  padding: 0 2px;
}
.tabs.top > li {
  border-bottom: 1px solid #369BFF;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
  margin-right: 2px;
  display: inline-block;
  max-width: 10%;
}
.tabs.left {
  border-right: 2px solid #369BFF;
  padding: 2px 0;
}
.tabs.left > li {
  border-right: 1px solid #369BFF;
  margin-bottom: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -2px;
}
.tabs.left > li:hover {
  border-right-color: #369BFF;
  border-right: 2px solid #369BFF;
}
.tabs.right {
  border-left: 2px solid #369BFF;
  padding: 2px 0;
}
.tabs.right > li {
  border-left: 1px solid #369BFF;
  margin-bottom: 2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}
/***
 * Table:
 */
/**
 * Table:
 * `.table` - The main table with header and rows inside
 * 
 * 	@example
 * 	div.table.col-2
 * 		div.row.header
 * 			div.cell Column1
 * 			div.cell Column2
 * 		div.row.even
 * 			div.cell Data11
 * 			div.cell Data12
 * 		div.row.odd
 * 			div.cell Data11
 * 			div.cell Data12
 * 		div.row.even
 * 			div.cell Data11
 * 			div.cell Data12
 * 		div.row.odd.active
 * 			div.cell Data11
 * 			div.cell Data12
 */
.table {
  width: 100%;
}
.table.col-10 > .row > .cell {
  width: 10%;
}
.table.col-9 > .row > .cell {
  width: 11.11111111%;
}
.table.col-8 > .row > .cell {
  width: 12.5%;
}
.table.col-7 > .row > .cell {
  width: 14.28571429%;
}
.table.col-6 > .row > .cell {
  width: 16.66666667%;
}
.table.col-5 > .row > .cell {
  width: 20%;
}
.table.col-4 > .row > .cell {
  width: 25%;
}
.table.col-3 > .row > .cell {
  width: 33.33333333%;
}
.table.col-2 > .row > .cell {
  width: 50%;
}
.table.col-1 > .row > .cell {
  width: 100%;
}
.table > .row {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-left: 1px solid #dbdbc8;
  border-right: 1px solid #dbdbc8;
  border-bottom: 1px solid #EFEFE7;
}
.table > .row:last-child {
  border-bottom-color: #dbdbc8;
}
.table > .row.odd {
  background-color: #f7f7f7;
}
.table > .row > .cell {
  display: table-cell;
  padding: 5px 8px;
  font-weight: lighter;
  vertical-align: middle;
  word-wrap: break-word;
}
.table > .row.selected {
  background-color: #369BFF;
  color: #FFF;
  border-left-color: #369BFF;
  border-right-color: #369BFF;
}
.table > .row.header {
  color: #3B4344;
  font-size: 1em;
  text-align: left;
  border: 1px solid #dbdbc8;
  background-color: #EFEFE7;
}
.table > .row.header > .cell {
  font-weight: normal;
}
/***
 * Messages:
 */
/**
 * Popup message:
 * `.popup-msg` - A styling for a popup message
 * 
 * 	@example
 * 	div.popup-msg The popup message
 */
.popup-msg {
  border: 1px solid #EFEFE7;
  border-radius: 8px;
  padding: 10px 15px;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.1);
  background-color: #FFF;
  /**
	  * Error pop message:
	  * `.error` - Error styling for the popup message
	  * 
	  * 	@example
	  * 	div.popup-msg.error The error popup message
	  */
}
.popup-msg.error {
  border-color: #ED3F40;
}
.popup-msg .close-but {
  float: right;
  cursor: pointer;
}
.popup-msg p {
  margin: 0;
}
.popup-msg .btn {
  margin: 0 10px;
}
/**
 * Float:
 * `.float` - A float style that makes the popup message float over the content
 */
.float {
  position: fixed;
  top: 5px;
  width: 100%;
  left: 0;
  z-index: 10000;
}
/***
 * Helpers:
 */
/**
 * Remove:
 * `.remove` - Removes a div.
 * 
 * 	@example
 * 	div.remove Test
 */
.remove {
  display: none;
}
/***
 * Layout:
 */
/**
 * One side fixed layout:
 * `.layout-anchor` - Base for a layout where one side is fixed
 * 
 * 	@example
 * 	div.layout-anchor
 */
.layout-anchor {
  display: table;
  /**
	 * Left anchored:
	 * `.layout-anchor.left` - Left anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.left
	 * 		div(style="background-color:#ccc") Left
	 * 		div(style="background-color:#aaa") Right
	 */
  /**
	 * Right anchored:
	 * `.layout-anchor.right` - Right anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.right
	 * 		div(style="background-color:#ccc") Left
	 * 		div(style="background-color:#aaa") Right
	 */
  /**
	 * Bottom anchored:
	 * `.layout-anchor.bottom` - Bottom anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.bottom(style="height:120px")
	 * 		div(style="background-color:#ccc") Top
	 * 		div(style="background-color:#aaa") Bottom
	 */
  /**
	 * Top anchored:
	 * `.layout-anchor.top` - Top anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.top(style="height:120px")
	 * 		div(style="background-color:#ccc") Top
	 * 		div(style="background-color:#aaa") Bottom
	 */
}
.layout-anchor.left {
  border-spacing: 15px 0;
  margin: 0 -15px;
}
.layout-anchor.left > * {
  display: table-cell;
}
.layout-anchor.left > :first-child {
  width: auto;
}
.layout-anchor.left > :last-child {
  width: 100%;
}
.layout-anchor.right {
  border-spacing: 15px 0;
  margin: 0 -15px;
}
.layout-anchor.right > * {
  display: table-cell;
}
.layout-anchor.right > :first-child {
  width: 100%;
}
.layout-anchor.right > :last-child {
  width: auto;
}
.layout-anchor.bottom {
  border-spacing: 0 15px;
  margin: -15px 0;
}
.layout-anchor.bottom > * {
  display: table-row;
}
.layout-anchor.bottom > :first-child {
  height: 100%;
}
.layout-anchor.bottom > :last-child {
  height: auto;
}
.layout-anchor.top {
  border-spacing: 0 15px;
  margin: -15px 0;
}
.layout-anchor.top > * {
  display: table-row;
}
.layout-anchor.top > :first-child {
  height: auto;
}
.layout-anchor.top > :last-child {
  height: 100%;
}
/**
 * Old panel should be replaced with a responsive one.
 */
.overlay-content {
  padding: 10px;
  background-color: #FFF;
  position: relative;
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
.overlay-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.overlay-mask {
  background-color: rgba(0, 0, 0, 0.5);
}
/**
 * Dropdown:
 * `.dropdown` - Styling for a html dropdown
 * 
 * 	@example
 * 	div.dropdown
 * 		div.dropdown--button Add
 * 		div.dropdown--menu
 */
.dropdown {
  position: relative;
}
.dropdown > .dropdown--menu {
  position: absolute;
  top: 33px;
  left: 0;
  background-color: #FFF;
  color: #3B4344;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
  -webkit-box-shadow: 0px 2px 3px 3px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 2px 3px 3px rgba(50, 50, 50, 0.1);
  box-shadow: 0px 2px 3px 3px rgba(50, 50, 50, 0.1);
  border-radius: 4px;
  border-top-left-radius: 0;
}
.dropdown > .dropdown--menu > li {
  padding: 4px 12px;
  transition: background-color 0.3s, color 0.4s;
  cursor: pointer;
}
.dropdown > .dropdown--menu > li:hover {
  background-color: #369BFF;
  color: #FFF;
}
/***
 * Inputs:
 */
/**
 * Input:
 * `.input` - The base styling for an dateneditor input
 * 
 * 	@example
 * 	div.input
 * 		div.label The label
 *		div.value The value
 */
.input {
  margin-top: 12px;
  font-size: 1em;
}
.input > .hint {
  cursor: help;
}
.input.attribute .validation-error {
  display: none;
}
.input.attribute.error .validation-error {
  display: block;
}
.input.attribute.error .status-icon.icon-error {
  display: block;
  color: #ED3F40;
}
.input.attribute.success .status-icon.icon-success {
  display: block;
  color: #1CA52D;
}
.input.attribute .status-icon {
  position: absolute;
  top: 15px;
  right: 17px;
  z-index: 10;
  font-size: 14px;
  display: none;
}
.input.error .form {
  border-color: #ED3F40;
}
.input.success .form {
  border-color: #1CA52D;
}
.validation-error {
  font-size: 0.9em;
  font-weight: bold;
  padding: 2px;
  color: #b51112;
}
/**
 * Infoinput:
 * `.input.info` - The style for a info input
 * 
 * 	@example
 * 	div.input.info
 * 		div.label My Attribute
 * 		div.value My Value
 */
.info.input .value {
  background-color: #EFEFE7;
  border-radius: 4px;
  padding: 6px 12px;
  box-sizing: border-box;
}
/**
 * Array input:
 * `.input.array` - The style for an array input
 *
 * 	@example
 * 	div.input.array
 * 		div.label My Attribute
 * 		div.tags
 * 			div.tag
 * 				div.tag-name First Tag
 * 				span.remove x
 * 			div.tag
 * 				div.tag-name Second Tag
 * 				span.remove x
 */
/**
 * Code input:
 * `.input.code` - The style for the code input
 */
.input.code .ace {
  border-radius: 4px;
}
/**
 * Group input:
 * `.input.group` - The style for grouped inputs
 * 
 * 	@example
 * 	div.input.group.open
 * 		div.label My Group
 * 		div.group-content My Value
 */
.input.group {
  width: 100%;
}
.input.group > .label {
  color: #3B4344;
  font-weight: bold;
  cursor: pointer;
}
.input.group > .label .icon {
  font-size: 12px;
  padding: 0;
  margin-right: 3px;
  vertical-align: middle;
  transition: all 0.2s;
}
.input.group > .group-content {
  display: none;
  padding: 0 10px 10px 10px;
  transition: all 0.3s;
}
.input.group.open > .label {
  border-bottom: 1px solid #EFEFE7;
}
.input.group.open > .label .icon {
  transform: rotate(90deg) translateX(-1px);
  margin-right: 5px;
}
.input.group.open > .group-content {
  margin-top: 0;
  display: block;
}
/**
 * Boolean input:
 * `.input.boolean` - The boolean switch
 * 
 * 	@example
 * 	div.input.boolean
 * 		div.label My Boolean
 * 		div.switch.form
 * 			div.state On
 * 			div.state Off
 * 			div.handle
 */
.input.boolean .switch {
  position: relative;
  display: table;
  background-color: #585858;
  color: #FFF;
  border-radius: 4px;
  padding: 0;
  cursor: pointer;
}
.input.boolean .switch .state {
  width: 50%;
  display: table-cell;
  text-align: center;
  padding: 6px 12px;
}
.input.boolean .switch .handle {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #FFF;
  border-radius: 3px;
  box-shadow: inset 0px 2px 3px #fff;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
  background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.input.boolean .switch.on .handle {
  left: 50%;
}
/**
 * Image input:
 * `.input.image` - The style for the image input
 */
.input.image .form {
  text-align: center;
}
.medium-editor-toolbar {
  left: 0;
  top: 0;
  position: absolute;
  visibility: hidden;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
  border-radius: 4px;
  border: 1px solid #585858;
  background-color: #585858;
}
.medium-editor-toolbar li {
  display: inline-block;
}
.medium-editor-toolbar.medium-editor-toolbar-active {
  visibility: visible;
}
.medium-editor-toolbar-form {
  display: none;
  background-color: #585858;
  color: #FFF;
  padding: 0 5px;
}
.medium-editor-toolbar-form.medium-editor-toolbar-form-active {
  display: block;
}
.medium-editor-toolbar-form input {
  border: none;
  background: none;
  font-size: 14px;
  margin: 0;
  padding: 6px;
  width: 316px;
  display: inline-block;
  outline: none;
  color: #FFF;
}
.medium-editor-toolbar-form a {
  color: #FFF;
  text-decoration: none;
  padding: 0 5px;
}
.medium-editor-toolbar-actions {
  margin: 0;
  padding: 0;
}
.medium-editor-toolbar-actions button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  outline: none;
  color: #FFF;
}
.medium-editor-toolbar-actions button:hover {
  background-color: #656565;
}
.medium-editor-toolbar-actions button .icon {
  font-size: 20px;
}
.medium-toolbar-arrow-under:after {
  content: '';
  position: absolute;
  height: 11px;
  width: 11px;
  background-color: #585858;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%) rotate(45deg);
}
.medium-editor-anchor-preview {
  left: 0;
  line-height: 1.4;
  max-width: 280px;
  position: absolute;
  text-align: center;
  top: 0;
  word-break: break-all;
  word-wrap: break-word;
  visibility: hidden;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px;
}
.medium-editor-anchor-preview a {
  text-decoration: none;
  color: #FFF;
}
.medium-editor-anchor-preview-active {
  visibility: visible;
}
.richtext .editor p {
  margin: 0;
}
.richtext .editor p + p {
  margin-top: 7px;
}
.model-editor {
  position: relative;
}
.model-editor > .mod-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
/***
 * Tabeditor:
 */
.tab-editor {
  /**
	 * Left oriented tab-editor:
	 * `.tab-editor.left` - Styles the tab editor to show the tabs on the left side
	 * 
	 * 	@example
	 * 	div.tab-editor.left
	 * 		ol.tabs.left
	 * 			li Tab1
	 * 			li Tab2
	 * 			li.active Tab3
	 * 		div.model-editor The content of the tab editor.
	 */
  /**
	 * Right oriented tab-editor:
	 * `.tab-editor.right` - Styles the tab editor to show the tabs on the right side
	 * 
	 * 	@example
	 * 	div.tab-editor.right
	 * 		ol.tabs.right
	 * 			li Tab1
	 * 			li Tab2
	 * 			li.active Tab3
	 * 		div.model-editor The content of the tab editor.
	 */
}
.tab-editor.left {
  margin-left: -15px;
  margin-right: -15px;
}
.tab-editor.left:before,
.tab-editor.left:after {
  content: " ";
  display: table;
}
.tab-editor.left:after {
  clear: both;
}
.tab-editor.left > * {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  float: left;
}
.tab-editor.left > .tabs {
  padding-right: 0;
  width: 20%;
}
.tab-editor.left > .model-editor {
  width: 80%;
  border-left: 2px solid #369BFF;
  margin-left: -2px;
}
.tab-editor.right {
  margin-left: -15px;
  margin-right: -15px;
}
.tab-editor.right:before,
.tab-editor.right:after {
  content: " ";
  display: table;
}
.tab-editor.right:after {
  clear: both;
}
.tab-editor.right > * {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  float: left;
}
.tab-editor.right > .tabs {
  padding-left: 0;
  padding-right: 13px;
  width: 20%;
  left: 80%;
  margin-left: -2px;
}
.tab-editor.right > .model-editor {
  width: 80%;
  border-right: 2px solid #369BFF;
  right: 20%;
  margin-left: 2px;
}
.tab-editor.right > .model-editor h2 {
  margin-top: 0;
}
.tab-editor > .tabs > li {
  position: relative;
  transition: padding 0.3s;
}
.tab-editor > .tabs > li .mod-container {
  display: none;
  right: 3px;
  top: 0px;
}
.tab-editor > .tabs > li:hover {
  padding-right: 30px;
}
.tab-editor > .tabs > li:hover .mod-container {
  display: inline-block;
}
/***
 * Table Editor:
 */
.table-editor {
  /**
	 * Table editor:
	 * `.table-editor` - Styles a table editor with a model editor inside
	 *
	 * 	@example
	 * 	div.table-editor
	 * 		div.table.col-2
	 * 			div.row.header
	 * 				div.cell Column1
	 * 				div.cell Column2
	 * 			div.row.odd
	 * 				div.cell Data11
	 * 				div.cell Data12
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 * 			div.row.active.even
	 * 				div.cell Data21
	 * 				div.cell Data22
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 * 			div.model-editor
	 * 				h2 Model
	 * 				div.inputs.col.col-1-1
	 * 					div.string.input
	 * 						div.label Input
	 * 						div.value.form
	 * 			div.row.odd
	 * 				div.cell Data31
	 * 				div.cell Data32
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 * 			div.row.even
	 * 				div.cell Data41
	 * 				div.cell Data42
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 */
}
.table-editor > .table.content > .row {
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}
.table-editor > .table.content > .row > .mod-container {
  display: none;
  right: 3px;
  top: -2px;
}
.table-editor > .table.content > .row:hover {
  background-color: #369BFF;
  color: #FFF;
}
.table-editor > .table.content > .row:hover > .mod-container {
  display: inline-block;
}
.table-editor > .table.content > .model-editor {
  padding: 10px;
  border: 1px solid #69b4ff;
  border-top: none;
}
.table-editor > .table.content > .model-editor > h2 {
  margin-top: 0;
  font-size: 1.2em;
}
/* Overwrite the 100% width of the general .table class */
.table.half {
  width: 50%;
}
/***
 * Preview Editor:
 */
.preview-editor {
  /**
	 * Preview editor:
	 * `.preview-editor` - Styles a preview editor with a model editor inside
	 * 
	 * 	@example
	 * 	div.preview-editor
	 * 		div.previews
	 * 			div.proxy Template1
	 * 			div.proxy.active Template2
	 * 			div.model-editor
	 * 				h2 Model
	 * 				div.inputs.col.col-1-1
	 * 					div.string.input
	 * 						div.label Input
	 * 						div.value.form
	 */
}
.preview-editor > .previews > .proxy {
  cursor: pointer;
  border-left: 0px solid #369BFF;
  padding: 5px 10px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
}
.preview-editor > .previews > .proxy.selected {
  border-left: 8px solid #369BFF;
  margin-top: 20px;
}
.preview-editor > .previews > .proxy:hover {
  background-color: #e8f4ff;
}
.preview-editor > .previews > .proxy:hover > .mod-container {
  opacity: 1;
}
.preview-editor > .previews > .proxy > .mod-container {
  opacity: 0;
  transition: all 0.5s;
}
.preview-editor > .previews > .proxy > .mod-container .icon--btn {
  color: #369BFF;
}
.preview-editor > .previews > .edit > .model-editor {
  padding: 10px;
  margin-bottom: 20px;
}
.preview-editor > .previews > .edit > .model-editor > h2 {
  margin-top: 0;
  font-size: 1.2em;
}
.preview-editor > .previews > .edit > .model-editor.active {
  -webkit-animation: border-fade 0.5s;
  animation: border-fade 0.5s;
  border-left: 8px solid #369BFF;
}
/**
 * Fix for animate the border of the newly placed model editor
 */
@-webkit-keyframes border-fade {
  from {
    border-left: 0px solid #369BFF;
  }
  to {
    border-left: 8px solid #369BFF;
  }
}
@keyframes border-fade {
  from {
    border-left: 0px solid #369BFF;
  }
  to {
    border-left: 8px solid #369BFF;
  }
}
.imagemap-editor .imagemap {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.imagemap-editor .maps {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.imagemap-editor .map {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px dashed #FFF;
  cursor: pointer;
}
.imagemap-editor .map .icon {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
}
.imagemap-editor .map.active {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #369BFF;
}
.imagemap-editor .image {
  max-width: 100%;
}
.file-selector .controls {
  text-align: right;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}
.file-selector .progress {
  width: 0;
  background-color: #FFF;
  height: 2px;
}
.file-selector h2 {
  margin-bottom: 14px;
}
.file-list .header {
  margin-top: 10px;
}
.file-list .row {
  cursor: pointer;
}
.file-list .upload {
  width: auto !important;
  height: auto !important;
}
.file-list .cell.thumb {
  width: 20%;
}
.file-list .cell.thumb img {
  max-width: 40px;
  max-height: 40px;
}
.file-list .cell.name {
  width: 40%;
}
.file-list .cell.tags {
  width: 40%;
}
.file-details .del-but,
.file-details .dl-but {
  text-align: center;
  display: block;
  text-decoration: none;
}
.file-details .inputs {
  margin-bottom: 20px;
}
.file-details .buttons {
  margin-bottom: 12px;
}
.model-selector .controls {
  padding: 15px;
}
.mod {
  margin: 5px 0;
}
.mod.inline {
  display: inline-block;
  padding-right: 10px;
}
.mod-container.floating .mod {
  margin: 0px;
}
.mod-container {
  position: absolute;
  right: 10px;
  top: 10px;
}
.mod-container .mod {
  display: inline-block;
}
/***
 * Mods:
 */
/**
 * Tab add mod:
 * `.tab-editor .mod.add` - Styles the add mod in a tab list.
 * 
 * 	@example
 * 	div.tab-editor
 * 		ol.list.tabs.top
 * 			li.proxy Entry 1
 * 			li.mod.add
 * 				div.dropdown
 * 					button.btn.add-but.add-button Add
 */
.tab-editor > .tabs > .mod.add {
  padding: 0;
  overflow: visible;
}
.tab-editor > .tabs > .mod.add .icon-button--text {
  display: none;
}
.tab-editor > .tabs > .mod.add .btn.icon {
  border: none;
}
.tab-editor > .tabs > .mod.add .btn.icon > .icon {
  padding: 6px 8px 5px 8px;
}
.tabs.left > .mod.add > .dropdown > .dropdown--button,
.tabs.right > .mod.add > .dropdown > .dropdown--button {
  display: block;
}
.tabs.left > .mod.add > .dropdown > .dropdown--button .icon,
.tabs.right > .mod.add > .dropdown > .dropdown--button .icon {
  display: block;
}
.tabs.left > li > .mod.delete,
.tabs.right > li > .mod.delete {
  position: absolute;
  right: 9px;
}
.mod.filter .reset-but {
  margin: 10px 0;
}
.mod.filter .filter-selector {
  width: 33.33333333%;
}
.mod.link a {
  color: #369BFF;
}
/***
 * Mods:
 */
/**
 * Tab import mod:
 * `.tab-editor .mod.import` - Styles the import mod in a tab list.
 * 
 * 	@example
 * 	div.tab-editor
 * 		ol.list.tabs.top
 * 			li.proxy Entry 1
 * 			li.mod.import
 * 				button.btn.add-but.add-button Add
 */
.tab-editor > .tabs > .mod.import {
  padding: 0;
  overflow: visible;
}
.tab-editor > .tabs > .mod.import .icon-button--text {
  display: none;
}
.tab-editor > .tabs > .mod.import .btn.icon {
  border: none;
}
.tab-editor > .tabs > .mod.import .btn.icon > .icon {
  padding: 6px 8px 5px 8px;
}
.viewport-resize {
  overflow: hidden;
  -ms-overflow-y: auto;
  /* IE8 */
  overflow-y: auto;
}
.login-form {
  padding: 30px 0;
}
.login-form input[type="submit"] {
  margin: 20px 0;
  text-align: right;
}
.login-form .error {
  margin-bottom: 23px;
}
.inplace-editor {
  border: 0px;
  background-color: #FFF;
  font: inherit;
  width: 100%;
}
.inplace-editor:focus {
  outline: 0;
}
/**
 * Tags:
 * `.tags` - The style for a tag area
 * `.tag` - The style for a tag
 *
 * 	@example
 *	div.tags
 *		div.tag
 *			div.tag-name First Tag
 *			span.remove
 *		div.tag
 *			div.tag-name Second Tag
 *			span.remove
 */
.tags {
  display: inline-block;
}
.tags .tag {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 6px;
  margin-bottom: 6px;
}
.tags .tag > span.input {
  padding: 6px 2px 6px 12px ;
  margin: 0px;
  display: inline-block;
}
.tags .remove {
  display: inline-block;
  font-weight: bold;
  padding: 6px;
  margin-top: -1px;
  font-size: 16px;
}
.textlist-editor > div.items .item {
  display: table;
  width: 100%;
  margin-bottom: 6px;
}
.textlist-editor > div.items .item .textarea-col {
  width: 100%;
  display: table-cell;
}
.textlist-editor > div.items .item .remove-col {
  width: auto;
  display: table-cell;
  opacity: 0.2;
  transition: opacity 300ms linear;
  vertical-align: top;
  cursor: default;
}
.textlist-editor > div.items .item .handler-col {
  display: table-cell;
  min-width: 20px;
  background-color: #ccc;
  border-radius: 4px 0px 0px 4px;
  opacity: 0.2;
  transition: opacity 300ms linear;
}
.textlist-editor > div.items .item .input {
  margin: 0px;
}
.textlist-editor > div.items .item textarea {
  border-radius: 0px 4px 4px 0px;
  resize: none;
  min-height: 60px;
  overflow: hidden;
}
.textlist-editor > div.items .item.draggable .handler-col {
  cursor: move;
  opacity: 1;
}
.textlist-editor > div.items .item.draggable .remove-col {
  cursor: pointer;
  opacity: 1;
}
.textlist-editor > div.items .remove {
  display: inline-block;
  font-weight: bold;
  padding: 6px;
  margin-top: -1px;
}
body,
html {
  margin: 0;
}
* {
  box-sizing: border-box;
}
#editor {
  padding-bottom: 30px;
}

/***
 * Text styles:
 */

/**
 * H1:
 * `h1` - The h1 style
 * 
 * 	@example
 * 	h1 A demo h1 header
 */
h1 {
	font-weight: normal;
	font-size: 2.2em;
	margin-top: 41px;
	padding-bottom: 8px;
	border-bottom: 1px solid @bright-color;
	color: @dark-color;
}

/**
 * H2:
 * `h2` - The h2 style
 * 
 * 	@example
 * 	h2 A demo h2 header
 */
h2 {
	font-weight: bold;
	font-size: 1.6em;
	margin-top: 20px;
	margin-bottom: 5px;
	color: @dark-color;
}

/**
 * Body:
 * `body` - A body style for font.
 * 
 * 	@example
 * 	div My Test Font
 */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700,400,300);

body {
	font-family: 'Source Sans Pro', sans-serif;
}

/**
 * Label:
 * `.label` - A styling for a label
 * 
 * 	@example
 * 	div.label My label
 */
.label {
	font-size: 0.7em;
	font-weight: normal;
	text-transform: uppercase;
	color: @info-color;
	margin-bottom: 4px;
}

/**
 * Icon:
 * `.icon` - Default icon size 
 * 
 * 	@example
 * 	div.icon
 * 		div.icon(data-icon="b")
 */
.icon {
	font-size: 22px;
	display: inline-block;
	padding: 6px 0 0 0;
	
	/**
	 * Button icon:
	 * `.icon.icon--btn` - A styling for a icon button
	 * 
	 * 	@example
	 * 	div.icon.icon--btn(data-icon="b")
	 */
	&.icon--btn {
		color: @action-color;
		cursor: pointer;
		transition: color 0.3s;
		
		&:hover {
			color: lighten( @action-color, 20% );
		}
		
		/**
		 * Inverted button icon:
		 * `.icon.icon--btn.icon--inv` - A styling for an inverted icon button
		 * 
		 * 	@example
		 * 	div.icon.icon--btn.icon--inv(data-icon="b")
		 */
		&.icon--inv {
			color: #FFF;
		
			&:hover {
				color: darken( #FFF, 20% );
			}
		}
	}
	
}

/**
 * info:
 * `.info-text` - A small info text
 * 
 * 	@example
 * 	div.info-text Info message
 */
.info-text {
	color: lighten( @dark-color, 40% );
	padding: 5px 0;
	font-style: italic;
}
/***
 * Mods:
 */

/**
 * Tab add mod:
 * `.tab-editor .mod.add` - Styles the add mod in a tab list.
 * 
 * 	@example
 * 	div.tab-editor
 * 		ol.list.tabs.top
 * 			li.proxy Entry 1
 * 			li.mod.add
 * 				div.dropdown
 * 					button.btn.add-but.add-button Add
 */
.tab-editor > .tabs > .mod.add {
	
	padding: 0;
	overflow: visible;
	
	.icon-button--text {
		display: none;
	}
	
	.btn.icon {
		border: none;
		
		> .icon {
			padding: 6px 8px 5px 8px;
		}
	}
}

// fix for add mod left/right tab editor
.tabs.left, .tabs.right {
	
	&> .mod.add > .dropdown > .dropdown--button {
		display: block;
		
		.icon { display: block; }
	}
	
	&> li > .mod.delete {
		position: absolute;
		right: 9px;
	}
}
/**
 * Dropdown:
 * `.dropdown` - Styling for a html dropdown
 * 
 * 	@example
 * 	div.dropdown
 * 		div.dropdown--button Add
 * 		div.dropdown--menu
 */
.dropdown {
	
	position: relative;
	
	> .dropdown--menu {
		position: absolute;
		top: 33px;
		left: 0;
		background-color: #FFF;
		color: @dark-color;
		list-style: none;
		margin: 0;
		padding: 0;
		z-index: 1000;
		-webkit-box-shadow: 0px 2px 3px 3px rgba(50, 50, 50, 0.1);
		-moz-box-shadow:    0px 2px 3px 3px rgba(50, 50, 50, 0.1);
		box-shadow:         0px 2px 3px 3px rgba(50, 50, 50, 0.1);
		border-radius: @border-radius;
		border-top-left-radius: 0;
		
		> li {
			padding: 4px 12px;
			transition: background-color 0.3s, color 0.4s;
			cursor: pointer;
			
			&:hover {
				background-color: @action-color;
				color: #FFF;
			}
		}
	}
}
/***
 * Form definitions:
 */

/**
 * form:
 * `.form` - A style for all types of forms. 
 * 
 * 	@example
 * 	input.form(value="Text")
 */
.form {
	font: inherit;
	display: block;
	width: 100%;
	padding: 6px 12px;
	line-height: 1.3em;
	min-height: 34px;
	font-size: 1em;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: @border-radius;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	box-sizing: border-box;
	position: relative;

	/**
	 * Form with icon:
	 * `.form > .icon` - A form with an overlying icon on the right side
	 * 
	 * 	@example
	 * 	div.form Text
	 * 		div.icon(data-icon="b")
	 */
	> .icon {
		position: absolute;
		top: 0;
		right: 6px;
	}
	
	&:focus {
		border-color: @info-color;
		outline: none;
		-webkit-box-shadow: inset 0 1px 1px lighten( @info-color, 30% );
		box-shadow: inset 0 1px 1px lighten( @info-color, 30% );
	}
}

select.form {
	height: 34px;
}

/**
 * Tags:
 * `.tags` - The style for a tag area
 * `.tag` - The style for a tag
 *
 * 	@example
 *	div.tags
 *		div.tag
 *			div.tag-name First Tag
 *			span.remove
 *		div.tag
 *			div.tag-name Second Tag
 *			span.remove
 */
.tags {

	display: inline-block;

	.tag {
		display: inline-block;
		border: 1px solid #ccc;
		border-radius: @border-radius;
		margin-right: 6px;
		margin-bottom: 6px;

		& > span.input {
			padding: 6px 2px 6px 12px ;
			margin: 0px;
			display: inline-block;
		}

	}

	.remove {
		display: inline-block;
		font-weight: bold;
		padding: 6px;
		margin-top: -1px;
		font-size: 16px;
	}
}
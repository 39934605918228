@import "variables.less";
@import "functions.less";
@import "fonts.less";
@import "global/button.less";
@import "global/text.less";
@import "global/responsive.less";
@import "global/forms.less";
@import "global/tabs.less";
@import "global/table.less";
@import "global/message.less";
@import "global/util.less";
@import "global/layout.less";
@import "global/panel.less";
@import "global/dropdown.less";

@import "inputs/base.less";
@import "inputs/info.less";
@import "inputs/array.less";
@import "inputs/code.less";
@import "inputs/group.less";
@import "inputs/boolean.less";
@import "inputs/image.less";
@import "inputs/richtext.less";

@import "editors/model.less";
@import "editors/tab.less";
@import "editors/table.less";
@import "editors/preview.less";
@import "editors/imagemap.less";

@import "panel/file-selector.less";
@import "panel/model-selector.less";

@import "mods/general.less";
@import "mods/container.less";
@import "mods/add.less";
@import "mods/filter.less";
@import "mods/link.less";
@import "mods/import.less";
@import "mods/resize.less";

@import "login/login.less";

@import "helpers/inplace.less";
@import "helpers/tag.less";
@import "helpers/textlist.less";

body,html {
	margin: 0;
}

* {
	box-sizing: border-box;
}
#editor {
	padding-bottom: 30px;
}


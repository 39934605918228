
.textlist-editor > div.items {

	.item {
		display: table;
		width: 100%;
		margin-bottom: 6px;	

		.textarea-col {
			width: 100%;
			display: table-cell;
		}

		.remove-col {
			width: auto;
			display: table-cell;
			opacity: 0.2;
			transition: opacity 300ms linear; 
			vertical-align: top;
			cursor: default;
		}

		.handler-col {
			display: table-cell;
			min-width: 20px;
			background-color: #ccc; 
			border-radius: 4px 0px 0px 4px;	
			opacity: 0.2;
			transition: opacity 300ms linear; 
		}

		.input {
			margin: 0px;
		}

		textarea {
			border-radius: 0px 4px 4px 0px;
			resize: none;
			min-height: 60px;
			overflow: hidden;
		}

		&.draggable {
			.handler-col {
				cursor: move;
				opacity: 1;
			}
			.remove-col {
				cursor: pointer;
				opacity: 1;
			}
		}
		
	}

	.remove {
		display: inline-block;
		font-weight: bold;
		padding: 6px;
		margin-top: -1px;
	}
}
/**
 * Old panel should be replaced with a responsive one.
 */
.overlay-content {
	padding: 10px;
	background-color: #FFF;
	position: relative;
	-webkit-box-shadow: 2px 2px 10px rgba( 0, 0, 0, 0.2 );
	box-shadow: 2px 2px 10px rgba( 0, 0, 0, 0.2 );
	
	.close {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}

.overlay-mask {
	background-color: rgba( 0, 0, 0, 0.5 );
}
/***
 * Helpers:
 */

/**
 * Remove:
 * `.remove` - Removes a div.
 * 
 * 	@example
 * 	div.remove Test
 */
.remove {
	display: none;
}
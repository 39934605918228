/**
 * Boolean input:
 * `.input.boolean` - The boolean switch
 * 
 * 	@example
 * 	div.input.boolean
 * 		div.label My Boolean
 * 		div.switch.form
 * 			div.state On
 * 			div.state Off
 * 			div.handle
 */
.input.boolean {
	
	.switch {
		position: relative;
		display: table;
		background-color: #585858;
		color: #FFF;
		border-radius: @border-radius;
		padding: 0;
		cursor: pointer;
		
		.state {
			width: 50%;
			display: table-cell;
			text-align: center;
			padding: 6px 12px;
		}
		
		.handle {
			position: absolute;
			width: 50%;
			height: 100%;
			background-color: #FFF;
			border-radius: @border-radius - 1;
			box-shadow: inset 0px 2px 3px #fff;
			background-color: #f7f7f7;
			background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
			background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
			background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
			background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
			background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
			top: 0;
			left: 0;
			-webkit-transition: all 0.4s ease-in-out;
			-moz-transition: all 0.4s ease-in-out;
			-o-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;
		}
		
		&.on .handle {
			left: 50%;
		}
	}
}
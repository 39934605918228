/***
 * Table:
 */

.generate-columns( @i ) when ( @i > 0 ) {

	&.col-@{i} {
		& >.row >.cell {
			width: 100% / @i
		}
	}
	.generate-columns( @i - 1 );
}
/**
 * Table:
 * `.table` - The main table with header and rows inside
 * 
 * 	@example
 * 	div.table.col-2
 * 		div.row.header
 * 			div.cell Column1
 * 			div.cell Column2
 * 		div.row.even
 * 			div.cell Data11
 * 			div.cell Data12
 * 		div.row.odd
 * 			div.cell Data11
 * 			div.cell Data12
 * 		div.row.even
 * 			div.cell Data11
 * 			div.cell Data12
 * 		div.row.odd.active
 * 			div.cell Data11
 * 			div.cell Data12
 */
.table {
	
	width: 100%;
	
	.generate-columns( 10 );
	
	& > .row {
	
		display: table;
		table-layout: fixed;
		width: 100%;
		border-left: 1px solid darken( @bright-color, 10% );
		border-right: 1px solid darken( @bright-color, 10% );
		border-bottom: 1px solid @bright-color;
		
		&:last-child {
			border-bottom-color: darken( @bright-color, 10% );
		}
		
		&.odd {
			background-color: #f7f7f7;
		}
		
		& > .cell {
			display: table-cell;
			padding: 5px 8px;
			font-weight: lighter;
			vertical-align: middle;
			word-wrap: break-word;
		}
		
		&.selected {
			background-color: @info-color;
			color: #FFF;
			border-left-color: @info-color;
			border-right-color: @info-color;
		}
	}
	
	& > .row.header {
	
		& > .cell {
			font-weight: normal;
		}
		
		color: @dark-color;
		font-size: 1em;
		text-align: left;
		border: 1px solid darken( @bright-color, 10% );
		background-color: @bright-color;
	}
}





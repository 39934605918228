/***
 * Reponsive:
 */

/**
 * Container:
 * `.container` - A main website container that centers the container and has the corresponing responsive jumps
 * 
 * 	@example
 * 	div.container(style='background-color:#AAA;height:50px;') 
 */
.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	
	@media (min-width: @breakpoint ) {
		width: 750px;
	}
	@media (min-width: 992px ) {
		width: 970px;
	}
	@media (min-width: 1200px ) {
		width: 1170px;
	}
}

/**
 * Columns:
 * `.col` - A base styling for any number of columns
 * 
 * 	@example
 * 	div.col
 */
.col {
	
	@media (min-width: @breakpoint ) {
		.columns();
	}
}

/**
 * Half column width modifier:
 * `.half` - A style that defines the width to 50%.
 * 
 * 	@example
 * 	div.col
 * 		div.half(style="background-color:#ccc") One side
 * 		div.half(style="background-color:#aaa") Other side
 */
.half {
	
	@media (min-width: @breakpoint ) {
		width: 100% / 2;
	}
}

/**
 * Third column width modifier:
 * `.third` - A style that defines the width to 100/3 %.
 * 
 * 	@example
 * 	div.col
 * 		div.third(style="background-color:#ccc") One side
 * 		div.third(style="background-color:#aaa") Other side
 * 		div.third(style="background-color:#bbb") Third side
 */
.third {
	
	@media (min-width: @breakpoint ) {
		width: 100% / 3;
	}
}

/**
 * Full column width modifier:
 * `.full` - A style that defines the width to 100%.
 * 
 * 	@example
 * 	div.col
 * 		div.full(style="background-color:#ccc") Full content
 */
.full {
	width: 100%;
}

/**
 * 80% / 20% column width modifier:
 * `.col-80, .col-20` - Two styles that defines the width to 80% or 20%.
 * 
 * 	@example
 * 	div.col
 * 		div.col-80(style="background-color:#ccc") One side
 * 		div.col-20(style="background-color:#aaa") Other side
 */
.col-20 {
	
	@media (min-width: @breakpoint ) {
		width: 20%;
	}
}
.col-80 {
	
	@media (min-width: @breakpoint ) {
		width: 80%;
	}
}

/**
 * Responsive image container:
 * `.responsive-img` - A style that makes the image scaling to its max width.
 * 
 * 	@example
 * 	img.responsive-img(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
 */
.responsive-img {
	max-width: 100%;
	height: auto;
}

/**
 * Responsive 4:3/16:9 media container:
 * `.responsive-media .media-4-3, .responsive-media .media-16-9` - This makes a div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
 * The media element needs the class `.media`. The `.responsive-media` container can have one of the following classes: `.media-4-3`, `.media-16-9` to define the fix ratio.
 * 
 * 	@example
 * 	div.responsive-media.media-4-3
 * 		iframe.media(src='//www.youtube.com/embed/HhZaHf8RP6g',frameborder='0')
 */
.responsive-media {
	width: 100%;
	position: relative;
	padding-bottom: 50%;
	
	& > .media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	&.media-4-3 {
		padding-bottom: 75%;
	}
	
	&.media-16-9 {
		padding-bottom: 56.26%
	}
}


.mod {
	
	margin: 5px 0;
	
	&.inline {
		display: inline-block;
		padding-right: 10px;
	}
}

.mod-container.floating {
	.mod {
		margin: 0px;
	}
}
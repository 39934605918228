/***
 * Layout:
 */

/**
 * One side fixed layout:
 * `.layout-anchor` - Base for a layout where one side is fixed
 * 
 * 	@example
 * 	div.layout-anchor
 */
.layout-anchor {
	display: table;
	
	
	/**
	 * Left anchored:
	 * `.layout-anchor.left` - Left anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.left
	 * 		div(style="background-color:#ccc") Left
	 * 		div(style="background-color:#aaa") Right
	 */
	&.left {
		
		border-spacing: 15px 0;
		margin: 0 -15px;
		
		& > * {
			display: table-cell;
		}
		
		& > :first-child {
			width: auto;
		}
		& > :last-child {
			width: 100%;
		}
	}
	
	/**
	 * Right anchored:
	 * `.layout-anchor.right` - Right anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.right
	 * 		div(style="background-color:#ccc") Left
	 * 		div(style="background-color:#aaa") Right
	 */
	&.right {
		
		border-spacing: 15px 0;
		margin: 0 -15px;
		
		& > * {
			display: table-cell;
		}
		
		& > :first-child {
			width: 100%;
		}
		& > :last-child {
			width: auto;
		}
	}
	
	/**
	 * Bottom anchored:
	 * `.layout-anchor.bottom` - Bottom anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.bottom(style="height:120px")
	 * 		div(style="background-color:#ccc") Top
	 * 		div(style="background-color:#aaa") Bottom
	 */
	&.bottom {
		
		border-spacing: 0 15px;
		margin: -15px 0;
		
		& > * {
			display: table-row;
		}
		
		& > :first-child {
			height: 100%;
		}
		& > :last-child {
			height: auto;
		}
	}
	
	/**
	 * Top anchored:
	 * `.layout-anchor.top` - Top anchor layout
	 * 
	 * 	@example
	 * 	div.layout-anchor.top(style="height:120px")
	 * 		div(style="background-color:#ccc") Top
	 * 		div(style="background-color:#aaa") Bottom
	 */
	&.top {
		
		border-spacing: 0 15px;
		margin: -15px 0;
		
		& > * {
			display: table-row;
		}
		
		& > :first-child {
			height: auto;
		}
		& > :last-child {
			height: 100%;
		}
	}
 
}
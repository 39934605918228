.imagemap-editor {
	
	.imagemap {
		position: relative;
		display: inline-block;
		max-width: 100%;
	}
	
	.maps {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	.map {
		position: absolute;
		background-color: rgba( 0, 0, 0, 0.4 );
		border: 1px dashed #FFF;
		cursor: pointer;
		
		.icon {
			position: absolute;
			top: 0px;
			right: 5px;
			cursor: pointer;
		}
		
		&.active {
			background-color: rgba( 255, 255, 255, 0.5 );
			border: 1px solid @info-color;
		}
	}
	
	.image {
		max-width: 100%;
	}
	
}
/**
 * Infoinput:
 * `.input.info` - The style for a info input
 * 
 * 	@example
 * 	div.input.info
 * 		div.label My Attribute
 * 		div.value My Value
 */
.info.input {
	
	.value {
		background-color: @bright-color;
		border-radius: @border-radius;
		padding: 6px 12px;
		box-sizing: border-box;
	}
}
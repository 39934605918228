/***
 * Mods:
 */

/**
 * Tab import mod:
 * `.tab-editor .mod.import` - Styles the import mod in a tab list.
 * 
 * 	@example
 * 	div.tab-editor
 * 		ol.list.tabs.top
 * 			li.proxy Entry 1
 * 			li.mod.import
 * 				button.btn.add-but.add-button Add
 */
.tab-editor > .tabs > .mod.import {
	
	padding: 0;
	overflow: visible;
	
	.icon-button--text {
		display: none;
	}
	
	.btn.icon {
		border: none;
		
		> .icon {
			padding: 6px 8px 5px 8px;
		}
	}
}
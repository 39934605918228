/***
 * Tabeditor:
 */

.tab-editor {
	
	/**
	 * Left oriented tab-editor:
	 * `.tab-editor.left` - Styles the tab editor to show the tabs on the left side
	 * 
	 * 	@example
	 * 	div.tab-editor.left
	 * 		ol.tabs.left
	 * 			li Tab1
	 * 			li Tab2
	 * 			li.active Tab3
	 * 		div.model-editor The content of the tab editor.
	 */
	 &.left {
	 
	 	.columns();
		
		> .tabs {
			padding-right: 0;
			width: 20%;
		}
		
		> .model-editor {
			width: 80%;
			border-left: 2px solid @info-color;
			margin-left: -2px;
			
		}
	}
	
	/**
	 * Right oriented tab-editor:
	 * `.tab-editor.right` - Styles the tab editor to show the tabs on the right side
	 * 
	 * 	@example
	 * 	div.tab-editor.right
	 * 		ol.tabs.right
	 * 			li Tab1
	 * 			li Tab2
	 * 			li.active Tab3
	 * 		div.model-editor The content of the tab editor.
	 */
	 &.right {
	 
	 	.columns();
		
		> .tabs {
			padding-left: 0;
			padding-right: 13px;
			width: 20%;
			left: 80%;
			margin-left: -2px;
		}
		
		> .model-editor {
			width: 80%;
			border-right: 2px solid @info-color;
			right: 20%;
			margin-left: 2px;
			h2 {
				margin-top: 0;
			}
		}
	}
	
	
	> .tabs > li {
		
		position: relative;
		transition: padding 0.3s;
		
		.mod-container {
			display: none;
			right: 3px;
			top: 0px;
		}
		
		&:hover {
			.mod-container {
				display: inline-block;
			}
			
			padding-right: 30px;
		}
	}
	
}
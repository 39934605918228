/***
 * Table Editor:
 */

.table-editor {

	/**
	 * Table editor:
	 * `.table-editor` - Styles a table editor with a model editor inside
	 *
	 * 	@example
	 * 	div.table-editor
	 * 		div.table.col-2
	 * 			div.row.header
	 * 				div.cell Column1
	 * 				div.cell Column2
	 * 			div.row.odd
	 * 				div.cell Data11
	 * 				div.cell Data12
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 * 			div.row.active.even
	 * 				div.cell Data21
	 * 				div.cell Data22
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 * 			div.model-editor
	 * 				h2 Model
	 * 				div.inputs.col.col-1-1
	 * 					div.string.input
	 * 						div.label Input
	 * 						div.value.form
	 * 			div.row.odd
	 * 				div.cell Data31
	 * 				div.cell Data32
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 * 			div.row.even
	 * 				div.cell Data41
	 * 				div.cell Data42
	 * 				div.mod.delete
	 * 					div.icon.icon-close.delete-but
	 */

	 & > .table.content {

	 	& >.row {
	 		cursor: pointer;

	 		position: relative;
			transition: all 0.3s;

			> .mod-container {
				display: none;
				right: 3px;
				top: -2px;
			}

			&:hover {
				> .mod-container {
					display: inline-block;
				}

				background-color: @action-color;
				color: #FFF;
			}
	 	}

	 	& >.model-editor {

		 	padding: 10px;
			border: 1px solid lighten( @info-color, 10% );
			border-top: none;

		 	& > h2 {
		 		margin-top: 0;
		 		font-size: 1.2em;
		 	}
		}
	}
}

/* Overwrite the 100% width of the general .table class */
.table.half {
	width: 50%;
}

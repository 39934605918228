.medium-editor-toolbar {

	left: 0;
	top: 0;
	position: absolute;
	visibility: hidden;
	box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
	border-radius: @border-radius;
	border: 1px solid #585858;
	background-color: #585858;

	li {
		display: inline-block;
	}

	&.medium-editor-toolbar-active {
		visibility: visible;
	}
}

.medium-editor-toolbar-form {
	display: none;
	background-color: #585858;
	color: #FFF;
	padding: 0 5px;

	&.medium-editor-toolbar-form-active {
		display: block;
	}

	input {
		border: none;
		background: none;
		font-size: 14px;
		margin: 0;
		padding: 6px;
		width: 316px;
		display: inline-block;
		outline: none;
		color: #FFF;
	}

	a {
		color: #FFF;
		text-decoration: none;
		padding: 0 5px;
	}
}

.medium-editor-toolbar-actions {

	margin: 0;
	padding: 0;
	

	button {
		background: none;
		border: none;
		cursor: pointer;
		display: inline-block;
		outline: none;
		color: #FFF;

		&:hover {
			background-color: lighten( #585858, 5% );
		}

		.icon {
			font-size: 20px;
		}
	}
}

.medium-toolbar-arrow-under:after {
	content: '';
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: #585858;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%) rotate(45deg);
}

.medium-editor-anchor-preview {
	left: 0;
    line-height: 1.4;
    max-width: 280px;
    position: absolute;
    text-align: center;
    top: 0;
    word-break: break-all;
    word-wrap: break-word;
    visibility: hidden;
	z-index: 2000;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 4px 10px;
	border-radius: @border-radius;
	font-size: 13px;
	
	a {
		text-decoration: none;
		color: #FFF;
	}
}

.medium-editor-anchor-preview-active {
	visibility: visible;
}


.richtext {
	.editor {

		p {
			margin: 0;
		}

		p + p {
			margin-top: 7px;
		}
	}
}
/**
 * Group input:
 * `.input.group` - The style for grouped inputs
 * 
 * 	@example
 * 	div.input.group.open
 * 		div.label My Group
 * 		div.group-content My Value
 */
.input.group {
	
	width: 100%;
	
	> .label {
		color: @dark-color;
		font-weight: bold;
		cursor: pointer;
		
		.icon {
			font-size: 12px;
			padding: 0;
			margin-right: 3px;
			vertical-align: middle;
			transition: all 0.2s;
		}
	}
	
	> .group-content {
		display: none;
		padding: 0 10px 10px 10px;
		transition: all 0.3s;
	}
	
	&.open {

		> .label {
			border-bottom: 1px solid @bright-color;
			
			.icon {
				transform: rotate(90deg) translateX(-1px);
				margin-right: 5px;
			}
		}
		
		> .group-content {
			margin-top: 0;
			display: block;
		}
	}
	
	
}
/***
 * Less functions:
 */
 
/**
 * Cleafix:
 * `.clearfix()` - Adds a clearfix to an element
 */
.clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

/**
 * Columns:
 * `.columns` - Setups a style for floating columns
 */
.columns() {
	margin-left: -15px;
	margin-right: -15px;
		
	.clearfix();
	
	& > * {
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
		position: relative;
		float: left;
	}
}
@charset "UTF-8";

@font-face {
  font-family: "dateneditor";
  src:url("fonts/dateneditor.eot");
  src:url("fonts/dateneditor.eot?#iefix") format("embedded-opentype"),
    url("fonts/dateneditor.woff") format("woff"),
    url("fonts/dateneditor.ttf") format("truetype"),
    url("fonts/dateneditor.svg#dateneditor") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dateneditor" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "dateneditor" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: "\63";
}
.icon-close:before {
  content: "\61";
}
.icon-erase:before {
  content: "\62";
}
.icon-italic:before {
  content: "\65";
}
.icon-underline:before {
  content: "\66";
}
.icon-group:before {
  content: "\6b";
}
.icon-bold:before {
  content: "\64";
}
.icon-page-copy:before {
  content: "\6c";
}
.icon-pencil:before {
  content: "\6d";
}
.icon-external-link:before {
  content: "\6e";
}
.icon-success:before {
  content: "\68";
}
.icon-error:before {
  content: "\69";
}
.icon-import:before {
  content: "\67";
}
.icon-list-number:before {
  content: "\6a";
}
.icon-list-bullet:before {
  content: "\6f";
}
.icon-file-symlink-file:before {
  content: "\70";
}
.icon-strikethrough:before {
  content: "\71";
}
.icon-superscript:before {
  content: "\72";
}
.icon-subscript:before {
  content: "\73";
}
.icon-link:before {
  content: "\74";
}
.icon-quote:before {
  content: "\76";
}
.icon-align-center:before {
  content: "\77";
}
.icon-align-justify:before {
  content: "\78";
}
.icon-align-left:before {
  content: "\79";
}
.icon-align-right:before {
  content: "\7a";
}
.icon-code:before {
  content: "\41";
}

/***
 * Inputs:
 */

/**
 * Input:
 * `.input` - The base styling for an dateneditor input
 * 
 * 	@example
 * 	div.input
 * 		div.label The label
 *		div.value The value
 */
.input {
	
	margin-top: 12px;
	font-size: 1em;
	
	& > .hint {
		cursor: help;
	}
	
	&.attribute {
		
		.validation-error {
			display: none;
		}
		
		&.error {
			.validation-error {
				display: block;
			}
			
			.status-icon.icon-error {
				display: block;
				color: @error-color;
			}
		}
		
		&.success {
			.status-icon.icon-success {
				display: block;
				color: @success-color;
			}
		}
		
		.status-icon {
			position: absolute;
			top: 15px;
			right: 17px;
			z-index: 10;
			font-size: 14px;
			display: none;
		}
	}
	
	&.error {
		.form {
			border-color: @error-color;
		}
	}
	
	&.success {
		.form {
			border-color: @success-color;
		}
	}
}


.validation-error {
	font-size: 0.9em;
	font-weight: bold;
	padding: 2px;
	color: darken( @error-color, 20% );
}
// styling for the file selector
.file-selector {
	
	.controls {
		text-align: right;
	    border-top: 1px solid #ccc;
	    padding-top: 20px;
	}
	
	.progress {
		width: 0;
 		background-color: #FFF;
		height: 2px;
	}
	
	h2 {
		margin-bottom: 14px;
	}
}

.file-list {
	
	.header {
		margin-top: 10px;
	}
	
	.row {
		cursor: pointer;
	}
	
	.upload {
		width: auto !important;
		height: auto !important;
		
	}
	
	.cell {
		&.thumb {
			width: 20%;
			
			img {
				max-width: 40px; 
				max-height: 40px; 
			}
		}
		&.name {
			width: 40%;
		}
		&.tags {
			width: 40%;
		}
	}
}

.file-details {
	.del-but, .dl-but {
		text-align:center;
		display: block;
		text-decoration: none;
	}
	
	.inputs {
		margin-bottom: 20px;
	}
	
	.buttons {
		margin-bottom: 12px;
	}
}

.login-form {
	padding: 30px 0;
	
	input[type="submit"] {
		margin: 20px 0;
		text-align: right;
	}
	
	.error {
		margin-bottom: 23px;
	}
}
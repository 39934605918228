/***
 * Messages:
 */

/**
 * Popup message:
 * `.popup-msg` - A styling for a popup message
 * 
 * 	@example
 * 	div.popup-msg The popup message
 */
.popup-msg {
	
	border: 1px solid @bright-color;
	border-radius: @border-radius * 2;
	padding: 10px 15px;
	-webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.1);
	-moz-box-shadow:    2px 2px 5px 0px rgba(50, 50, 50, 0.1);
	box-shadow:         2px 2px 5px 0px rgba(50, 50, 50, 0.1);
	background-color: #FFF;
	
	 
	 /**
	  * Error pop message:
	  * `.error` - Error styling for the popup message
	  * 
	  * 	@example
	  * 	div.popup-msg.error The error popup message
	  */
	&.error {
		border-color: @error-color;
	}
	
	.close-but {
		float: right;
		cursor: pointer;
	}
	
	p {
		margin: 0;
	}
	
	.btn {
		margin: 0 10px;
	}
}

/**
 * Float:
 * `.float` - A float style that makes the popup message float over the content
 */
.float {

	position: fixed;
	top: 5px;
	width:100%;
	left: 0;
	z-index: 10000;
}



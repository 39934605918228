// styling for the filter mod
.mod.filter {
	
	.reset-but {
		margin: 10px 0;
	}
	
	.filter-selector {
		width: 100% / 3;
	}
}
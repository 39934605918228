.inplace-editor {
	border: 0px;
	background-color: #FFF;
	font: inherit;

	&:focus {
		outline: 0;
	}
	
	width: 100%;

}

